import LogoutSvg from "./LogoutSvg";
import SettingsSvg from "./SettingsSvg";
import "./ProfileOptions.css";
import { useState } from "react";
import ProfileWidget from "../profile-ui/ProfileWidget";
import { createPortal } from "react-dom";
import Cookies from 'js-cookie';

const ProfileOptions = ({ closeProfile, closeMobProf }) => {
  const [manageClicked, setManageClicked] = useState(false);

  const handleAccount = () => {
    setManageClicked(!manageClicked);
  };

  const handleLogOutClicked = () => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove('access_token');
    window.location.href = "/";
  };

  return (
    <div className="options-container">
      <div className="option one" onClick={handleAccount}>
        <SettingsSvg />
        <p className="manage-acc">Manage Account</p>
      </div>
      <div className="option" onClick={handleLogOutClicked}>
        <LogoutSvg />
        <p id="logOutOption">Logout</p>
      </div>
      {manageClicked &&
        createPortal(
          <ProfileWidget onClose={handleAccount} closeProfile={closeProfile} closeMobProf={closeMobProf} />,
          document.body
        )}
    </div>
  );
};

export default ProfileOptions;
