import StarRating from "../widgets/StarRating";
import axios from "axios";

export const getFileUrl = `${window.env_url}/v1/files/`;

export const token = localStorage.getItem("accessToken");

export const usertype = localStorage.getItem("usertype")

export const truncateDescription = (description, maxLength) => {
  return description?.length > maxLength
    ? description.substring(0, maxLength) + "..."
    : description;
};

export const truncateIntegerWithEllipsis = (number, maxLength) => {
  const numberString = number.toString();
  return numberString.length > maxLength
    ? numberString.substring(0, maxLength) + "..."
    : numberString;
};

export const extractFirstValue = (str, separator) => {
  if (str) {
    const strArray = str?.split(separator);
    const firstValue = strArray[0];
    return firstValue;
  }
};

export const extractAllValues = (str, seperator) => {
  if (str) {
    const strArray = str?.split(seperator);
    return strArray;
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    weekday: "short",
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Set to false if you want 24-hour format
  };
  return date.toLocaleTimeString("en-US", options);
};

export const hours = Array.from({ length: 24 }, (_, index) => ({
  name: `${index + 1} hour${index === 0 ? "" : "s"}`, // Creates "1 hour", "2 hours", etc.
  code: index + 1,
}));

export const customerMetaData = [
  {
    id: 1,
    imageurl: "https://picsum.photos/200/300",
    customerName: "Customer Name",
    content:
      " In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as aplaceholder before final copy is available",
  },
  {
    id: 2,
    imageurl: "https://picsum.photos/200/300",
    customerName: "Customer Name",
    content:
      " In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as aplaceholder before final copy is available",
  },
  {
    id: 3,
    imageurl: "https://picsum.photos/200/300",
    customerName: "Customer Name",
    content:
      " In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as aplaceholder before final copy is available",
  },
  {
    id: 4,
    imageurl: "https://picsum.photos/200/300",
    customerName: "Customer Name",
    content:
      " In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as aplaceholder before final copy is available",
  },
];

export const CustomerCard = ({ id, imgurl, customerName, content }) => {
  return (
    <div className="custR-coach-card mv" key={id}>
      <img src={imgurl} className="custR-coach-img" />
      <div className="customer-rating">
        <StarRating rating={4} totalStars={5} fontSize="5vw" />
      </div>
      <div className="customer-details">
        <span className="cust-name">
          {customerName}
          {id}
        </span>
        <span>{content}</span>
      </div>
    </div>
  );
};

export const groupArray = (array, groupSize) => {
  const groupedArray = [];

  for (let i = 0; i < array.length; i += groupSize) {
    const group = array.slice(i, i + groupSize);
    groupedArray.push(group);
  }

  return groupedArray;
};
