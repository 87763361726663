import { useEffect, useState } from "react";
import {
  extractFirstValue,
  formatDate,
  formatTime,
  token,
} from "../../../utils/utilityFunctions";
import axios from "axios";
import "./RoomBookingHistory.css";
import BookingRow from "./BookingRow";
import LoaderSpinner from "../../../utils/shimmer/LoaderSpinner";
import { Paginator } from "primereact/paginator";
import 'primereact/resources/themes/saga-blue/theme.css';   // Theme CSS
import 'primereact/resources/primereact.min.css';           // Core CSS
import 'primeicons/primeicons.css'

const RoomBookingHistory = () => {
  const [bookingData, setBookingData] = useState([]);
  const [roomNames, setRoomNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  useEffect(() => {
    fetchBookingData();
  }, []);

  const fetchBookingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/meetingbookings/byUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const bookings = response.data.body[0];
      setBookingData(bookings); // Update with the full array of booking data
      fetchRoomNames(bookings);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("Booking Data", bookingData);

  const fetchRoomNames = async (bookings) => {
    const roomNamesMap = {};
    try {
      setIsLoading(true)
      for (const booking of bookings) {
        const roomId = booking.bookedRoomId;
        if (!roomNamesMap[roomId]) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/rooms/${roomId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          roomNamesMap[roomId] =
            extractFirstValue(response.data.body[0]?.title, "|") ||
            "Unknown Room";
        }
      }
      setRoomNames(roomNamesMap);
    } catch (error) {
      console.error("Failed to fetch room names:", error.message);
    } finally {
      setIsLoading(false)
    }
  };


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatedData = bookingData?.slice(first, first + rows);

  if (isLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      {bookingData?.length === 0 ? (
        <h1 className="notFoundText rmh">No Bookings Found!</h1>
      ) : (
        <section>
          <table className="bookingRequestTable" id="bookingDetailsId">
            <thead>
              <tr>
                <th className="roomBookingHistory">Room Name</th>
                <th className="roomBookingHistory">Check-In</th>
                <th className="roomBookingHistory">Check-Out</th>
                <th className="roomBookingHistory">Duration</th>
                <th className="roomBookingHistory">Seats</th>
                <th className="roomBookingHistory">Facilities</th>
                <th className="roomBookingHistory">Status</th>
                <th className="roomBookingHistory">Review Information</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item) => (
                <BookingRow
                  key={item?.id}
                  item={item}
                  roomName={roomNames[item?.bookedRoomId]}
                />
              ))}
            </tbody>
          </table>
          <Paginator
            first={first}
            rows={rows}
            totalRecords={bookingData?.length}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
          </section>
      )}
    </>
  );
};

export default RoomBookingHistory;
