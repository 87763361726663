import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "./CalendarWidget.css";
import BookForm from "../../pages/form/BookForm";
import MultiSelectDropdown from "../../components/MultiSelect";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";
import { hours, token } from "../../utils/utilityFunctions";
import { Tooltip, colors, styled, tooltipClasses } from "@mui/material";
import { toast } from "react-toastify";
import Asterisk from "../../utils/Asterisk";
import SingleSelectDropdown from "../../components/SingleSelect";
import LoaderSpinner from "../../utils/shimmer/LoaderSpinner";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarWidget = ({
  selectedMonth,
  setSelectedMonth,
  calendarView,
  setCalendarView,
  roomName,
  setSelectedBooking,
  eventss,
  getAllEvents,
}) => {
  const [selectedFacilties, setSelectedFacilties] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log("selectedFacilties", selectedFacilties);
  const updateFacilities = (e) => {
    // setSelectedFacilties(e.value);
    const {
      target: { value },
    } = e;
    setSelectedFacilties(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const location = useLocation();
  const { state } = location;
  const { roomData } = state;
  const roomSeats = roomData.title.split("|")[1];

  const getFileUrl = `${window.env_url}/v1/files/`;

  const ref = collection(db, "userrequest");

  const { id } = useParams();

  console.log("roomSeats", roomSeats);

  const fetchFacilities = async () => {
    const apiUrl = `${window.env_url}/v1/filterfacilities`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFacilityData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const facilities = facilityData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  console.log("Facilities", facilities);

  // const hours =

  const [events, setEvents] = useState([
    {
      title: "Event 1",
      start: new Date(),
      allDay: true,
      end: new Date(2024, 2, 1),
    },
  ]);

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const onEventResize = (data) => {
    const { start, end } = data;
    setEvents([{ ...events[0], start, end }]);
  };
  const onEventDrop = (data) => {
    //////console.log(data);
  };

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    facilities: "",
    duration: "",
    notes: "",
    seats: "",
  });

  console.log("newEvent.seats", newEvent);

  const [allEvents, setAllEvents] = useState(events);

  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  //console.log("ev", eventss);

  const formattedEvents = eventss
    ?.filter(
      (event) =>
        event?.visibility === "accepted" || event?.visibility === "pending"
      // ||
      // (event?.visibility === "rejected" && event?.userId === numUserId)
    )
    .map((event) => {
      const startDateTime = moment(
        `${event.checkInDate} ${event.checkInTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const endDateTime = moment(
        `${event.checkOutDate} ${event.checkOutTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );

      return {
        id: event.id,
        title: "", // You can customize this if you have specific titles
        start: startDateTime.toDate(), // Format the start datetime
        end: endDateTime.toDate(), // Format the end datetime
        facilities: event.facilities, // Assuming event.facilities is an array of facilities
        notes: event.notes,
        seats: event?.seats,
        roomCapacity: roomSeats,
        isRescheduled: event?.isRescheduled,
        visibility: event?.visibility,
        userId: event?.userId,
      };
    });

  console.log("formattedEvents", formattedEvents);

  const EventComponent = ({ event }) => {
    let visibilityColor;
    let textColor;

    switch (event?.visibility) {
      case "accepted":
        visibilityColor = "#265985";
        textColor = "white";
        break;
      case "pending":
        visibilityColor = "yellow";
        textColor = "black";
        break;
      case "rejected":
        visibilityColor = "red";
        textColor = "white";
        break;
      default:
        visibilityColor = "black"; // Default color if visibility is not specified or unknown
        break;
    }

    const eventStyle = {
      backgroundColor: visibilityColor,
      color: textColor,
    };

    const facilities = event?.facilities
      ? event?.facilities
      : "No facility available";
    const notes = event?.notes;

    const tooltipContent = `
   Facilities : ${facilities}, 
   Notes : ${notes}
    `;

    return (
      <div style={eventStyle}>
        <Tooltip title={tooltipContent}>
          <div>{roomName}</div>
          <div style={{ textTransform: "capitalize" }}>
            Status : {event?.visibility}
          </div>
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    fetchFacilities();
    getAllEvents();
  }, []);

  useEffect(() => {
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    setSelectedMonth({ startDate, endDate });
  }, []);

  const today = new Date().toISOString().split("T")[0];

  const handleAddEvent = () => {
    const startTime = moment(newEvent.startTime, "HH:mm:ss");
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(newEvent.duration, "hours");

    let startDate = moment(newEvent.start);
    let endDate = moment(newEvent.end);
    let checkOutDateTime = moment(
      `${newEvent.start} ${newEvent.startTime}`,
      "YYYY-MM-DD HH:mm:ss"
    ).add(newEvent.duration, "hours"); // Calculate checkout date/time

    console.log(
      !startTimeMoment?._i,
      !endTimeMoment._i,
      !selectedFacilties?.length,
      !newEvent.duration,
      !(newEvent.notes === "")
    );
    if (
      !startTimeMoment?._i ||
      !endTimeMoment._i ||
      !selectedFacilties?.length ||
      !newEvent.duration ||
      newEvent.notes === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    if (Number(newEvent.seats) > Number(roomSeats)) {
      toast.error("You've exceeded the seats limit");
      return;
    }

    console.log("startDate", startDate);
    console.log("newEvent.duration", newEvent?.duration);
    const duration = newEvent?.duration;
    const roomEvent = {
      checkInDate: moment(startDate).format("YYYY-MM-DD"),
      checkOutDate: checkOutDateTime.format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      facilities: selectedFacilties.map((item, i) => item),
      seats: newEvent.seats,
      duration: newEvent.duration,
      notes: newEvent.notes,
      duration,
    };

    console.log("roomEvent", roomEvent);
    const event = roomEvent;
    //console.log("roomEv", event);
    setAllEvents([...allEvents, event]);
    const postRoomBooking = async () => {
      const token = localStorage.getItem("accessToken");

      try {
        setIsLoading(true);
        const response = await fetch(
          `${window.env_url}/v1/meetingbookings/book/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(event),
          }
        );
        const data = await response.json();
        ////console.log(response);
        if (response.ok) {
          toast.success("Room Booking request made");
        } else {
          if (data.status === 500) {
            toast.error("Provide valid details");
          } else {
            toast.error(data?.message);
          }
        }
        let dataToSend = {
          username: sessionStorage.getItem("fullName"),
          message: "Booked a meeting room",
          duration: data.body[0].duration,
          profileImage: imgPath,
          createdAt: Date.now(),
        };
        getAllEvents();
        ////console.log(dataToSend);
        try {
          await addDoc(ref, dataToSend);
          ////console.log("Data sent to Firebase successfully", dataToSend);
        } catch (firebaseError) {
          ////console.log("Error adding data to Firebase:", firebaseError);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setNewEvent({
          start: "",
          end: "",
          startTime: "",
          endTime: "",
          seats: "",
          facilities: "",
          duration: "",
          notes: "",
        });
        setSelectedFacilties([]);
        setIsLoading(false);
      }
    };

    postRoomBooking();
  };

  const handleViewChange = (view) => {
    if (view === Views.MONTH || view === "month") {
      const startDate = moment().startOf("month").format("YYYY-MM-DD");
      const endDate = moment()
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
      setCalendarView("Monthly");
    } else if (view === Views.WEEK || view === "week") {
      const startDate = moment().startOf("week").format("YYYY-MM-DD"); // Start of the current week (Sunday)
      const endDate = moment().endOf("week").format("YYYY-MM-DD"); // End of the current week (Saturday)
      setSelectedMonth({ startDate, endDate });
      setCalendarView("Weekly");
    } else if (view === Views.DAY || view === "day") {
      const startDate = moment().startOf("day").format("YYYY-MM-DD"); // Start of today
      const endDate = moment().startOf("day").format("YYYY-MM-DD"); // Start of today (same as start)
      setSelectedMonth({ startDate, endDate });
      setCalendarView("Day");
    }
  };

  const handleNavigate = (date, view) => {
    if (view === Views.MONTH || view === "month") {
      const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(date)
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
    } else if (view === Views.WEEK || view === "week") {
      const startDate = moment(date).startOf("week").format("YYYY-MM-DD"); // Start of the week (Sunday)
      const endDate = moment(date).endOf("week").format("YYYY-MM-DD"); // End of the week (Saturday)
      setSelectedMonth({ startDate, endDate });
    } else if (view === Views.DAY || view === "day") {
      const startDate = moment(date).startOf("day").format("YYYY-MM-DD"); // Start of the provided date
      const endDate = moment(date).startOf("day").format("YYYY-MM-DD"); // Start of the provided date (same as start)
      setSelectedMonth({ startDate, endDate });
    }
  };

  const calculateDuration = () => {
    if (newEvent.startTime && newEvent.endTime) {
      const start = moment(`2024-01-01T${newEvent.startTime}`);
      const end = moment(`2024-01-01T${newEvent.endTime}`);
      const durationMinutes = end.diff(start, "minutes");
      const durationUnits = durationMinutes / 15; // 15 minutes is 1 unit
      console.log("durationUni");
      setNewEvent({ ...newEvent, duration: durationUnits });
    }
  };

  return (
    <>
      <div className="tl-container">
        <div className="cal-wrapper" style={{ height: "auto" }}>
          <Calendar
            defaultDate={moment().toDate()}
            defaultView="month"
            events={formattedEvents}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            onView={(view) => handleViewChange(view)}
            onNavigate={(date, view) => handleNavigate(date, view)}
            resizable
            onSelectEvent={(event) => setSelectedBooking(event)}
            showMultiDayTimes
            views={["month", "week", "day"]}
            style={{ height: "100vh" }}
            components={{
              event: EventComponent, // Use the custom event component for all views
              agenda: { event: EventComponent }, // Also use the custom event component for agenda view
              week: { event: EventComponent }, // Also use the custom event component for week view
              day: { event: EventComponent }, // Also use the custom event component for day view
            }}
            titleAccessor={() => ""}
          />
        </div>
      </div>

      {width >= 1024 && (
        <div className="bmrf">
          {isLoading && (
            <div className="spinner-overlay">
              <LoaderSpinner />
            </div>
          )}

          <>
            <div className="bmrf-heading">
              <p>MAKE A BOOKING</p>
              <span
                style={{
                  fontFamily: "Nunito",
                  fontStyle: "italic",
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Fields with asterisk (*) are required
              </span>
            </div>
            <div className="bmrf-form">
              <div className="formFieldContainer">
                <div className="row1">
                  <div>
                    <label htmlFor="" className="makebold">
                      Date
                      <Asterisk />
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      value={newEvent.start}
                      onChange={(event) =>
                        setNewEvent({
                          ...newEvent,
                          start: event.target.value,
                        })
                      }
                      min={today}
                    />
                  </div>
                </div>

                <div className="row1">
                  <div>
                    <label htmlFor="" className="makebold">
                      Seats
                      <Asterisk />
                    </label>
                    <input
                      type="number"
                      placeholder={`Seating capacity is ${roomSeats}`}
                      name="seats"
                      value={newEvent.seats}
                      onChange={(event) => {
                        setNewEvent({
                          ...newEvent,
                          seats: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="row2">
                  <div>
                    <label htmlFor="" className="makebold">
                      Time
                      <Asterisk />
                    </label>
                    <input
                      type="time"
                      name="startTime"
                      value={newEvent.startTime}
                      onChange={(event) => {
                        setNewEvent({
                          ...newEvent,
                          startTime: event.target.value,
                        });
                        calculateDuration();
                      }}
                    />
                  </div>
                </div>

                <div className="row3" style={{ border: "none" }}>
                  <div>
                    <label htmlFor="" className="makebold">
                      Duration
                      <Asterisk />
                    </label>
                    {/* <input
                    type="number"
                    name="duration"
                    placeholder="Duration"
                    value={newEvent.duration}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                  /> */}
                    <SingleSelectDropdown
                      options={hours}
                      value={newEvent.duration}
                      onChange={(event) =>
                        setNewEvent({
                          ...newEvent,
                          duration: event.target.value,
                        })
                      }
                      label="Duration"
                      isMandatory={true}
                    />
                  </div>
                </div>

                <div className="row4">
                  <div>
                    <MultiSelectDropdown
                      value={selectedFacilties}
                      onChangeEvent={updateFacilities}
                      options={facilities}
                      isMandatory={true}
                      placeholder="Select Facilities"
                    />
                  </div>
                </div>

                <div className="row5">
                  <div className="row5" style={{ display: "block" }}>
                    <label htmlFor="" className="makebold">
                      Description
                      <Asterisk />
                    </label>
                    <textarea
                      type="text"
                      name="notes"
                      value={newEvent.notes}
                      placeholder="Description"
                      onChange={(event) =>
                        setNewEvent({
                          ...newEvent,
                          notes: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-sbt-btn" onClick={handleAddEvent}>
                <button>Submit</button>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default CalendarWidget;
