import LogoImage from "../../assets/png/Logo.png";
import "./Navbar.css";
import { ReactComponent as NavIcon } from "../../assets/svg/nav.svg";
import { ReactComponent as BellIcon } from "../../assets/svg/Notification.svg";
import { ReactComponent as Dropdown } from "../../assets/svg/Dropdown.svg";
import IncuHubLogo from "../../assets/png/IncuHubLogo.png"
import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";

import Sidebar from "../sidebar/Sidebar";
import NavigationWidget from "../../widgets/NotificationWidget.jsx";
import { useEffect, useState } from "react";
import ProfileWidget from "../../dialogs/profile-ui/ProfileWidget.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileOptions from "../../dialogs/profile-options/ProfileOptions.jsx";
import { useMediaQuery } from "@mui/material";

const Navbar = () => {
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const location = useLocation();
  const [navBarData, setNavBarData] = useState(null);
  const { pathname } = location;
  const getFileUrl = `${window.env_url}/v1/files/`;
  const userName = sessionStorage.getItem("fullName");
  const profImage = sessionStorage.getItem("profPicture");
  const handleProfile = () => {
    setIsProfileClicked(!isProfileClicked);
  };

  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");
  const isLaptopOrDesktop = useMediaQuery("(min-width: 1024px)");

  const closeOptions = () => {
    setIsProfileClicked(false);
  };

  const getUser = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${window.env_url}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setNavBarData(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  let imgPath;
  if (profImage?.includes("avataaars.io")) {
    imgPath = profImage;
  } else {
    imgPath = getFileUrl + navBarData?.image;
  }

  const navigate = useNavigate();
const adminImage = localStorage.getItem("adminImage")
console.log("adminImage", adminImage)
let appLogo;
if(adminImage === "undefined") {
  appLogo = IncuHubLogo
} else {
  appLogo = getFileUrl + localStorage.getItem("adminImage");
}
let appName;

if(localStorage.getItem("appname") === "undefined") {
  appName = "IncuHub"
} else {
  appName = localStorage.getItem("appname")
}

  return (
    <div className="margin-root">
      <nav className="navbarClass">
        {/* <div className="nav-icon">
          <NavIcon id="navIconSvg" />
        </div> */}
        <div
          className="navLogoContainer"
          onClick={() => navigate("/home")}
          style={{ cursor: "pointer" }}
        >
          <img className="nav-logo-image" src={appLogo} alt="" />
        </div>
        <div className="navbar_content">
          <div className="nav-incubator">
            <span>{appName}</span>
          </div>
          <div className="nav-notif-icon">
            <NavigationWidget />
          </div>
          <div className="user-details">
            {isLaptopOrDesktop && <p className="nav-username">{userName}</p>}
            <div className="user-det-img" style={{ cursor: "pointer" }}>
              <img src={imgPath} className="nav-user-dp" />
              <Dropdown onClick={handleProfile} />
            </div>
          </div>
          {isProfileClicked && <ProfileOptions closeProfile={closeOptions} />}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
