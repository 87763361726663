import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import "./Priority.css"; // Reusing NewsModal.css for consistent styling
import CustomCloseIcon from "../../assets/svg/CustomCloseIcon";

// Define reusable styles for different screen sizes
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 890,
  height: 666,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "32px 32px 32px 32px",
  borderRadius: 8,
};

const laptopStyle = {
  ...style,
  width: "54%",
  height: "70%",
};

const tabletStyle = {
  ...style,
  width: "40%",
  height: "68vw",
  borderRadius: 2,
};

const mobileStyle = {
  ...style,
  width: "42vw",
  height: "auto",
};

const modalStyles = {
  "& .MuiDialog-paper": {
    outline: "none",
    boxShadow: "none",
  },
};

const PriorityNewsDialog = ({
  open,
  closeNewsPriorityDialog,
  currentSlide,
  leftIcon,
  rightIcon,
  priorityNewsLeftHandler,
  priorityNewsRightHandler,
  priorityNews,
}) => {
  const isDesktop = useMediaQuery("(min-width: 1441px)");
  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        paper: {
          sx: modalStyles,
        },
      }}
    >
      <Box sx={isDesktop ? style : isTablet ? tabletStyle : mobileStyle}>
        <div className="newsContainer">
          <CustomCloseIcon fill="#FF0000" onClick={closeNewsPriorityDialog} />

          <div className="newsImage priority-image">
            {currentSlide?.newsItem?.files && (
              <img
                src={`${process.env.REACT_APP_API_URL}/v1/files/${currentSlide?.newsItem?.files}`}
                alt={currentSlide?.newsItem?.title || "news image"}
              />
            )}
          </div>

          <div className="newsDescriptionContainer">
            <div className="newsTitle">
              <h1>{currentSlide?.newsItem?.title}</h1>
            </div>
            <p>{currentSlide?.newsItem?.description}</p>
          </div>

          <div className="newsCarouselIcons">
            {currentSlide?.slideId > 0 && (
              <img
                className="newsCarouselIcon"
                src={leftIcon}
                onClick={priorityNewsLeftHandler}
                alt="previous"
              />
            )}
            {currentSlide?.slideId < priorityNews?.length - 1 && (
              <img
                className="newsCarouselIcon"
                src={rightIcon}
                onClick={priorityNewsRightHandler}
                alt="next"
              />
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PriorityNewsDialog;
